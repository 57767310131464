let initialTopics: string[]  = [''];


switch(process.env.REACT_APP_COUNTRY) {
  case "jp":
    initialTopics = [
      'IT',
      'テクノロジー',
      '科学',
      '映画',
      '料理',
      'グルメ',
      '音楽',
      '本',
      '旅行',
      'ビジネス',
      '心理学',
      '歴史',
      '健康',
      '写真',
      'スポーツ',
      'ゲーム',
      'ファッション',
      '美容',
      '運動',
      '勉強',
      '学校',
      '経済',
      '政治',
      'ファイナンス',
      '金融',
      'テレビ',
      '芸能',
      '教育',
      '報道',
      '文学',
      'アート',
      '芸術',
      '数学',
      '哲学',
      '物理',
      '医療',
      '化学',
      '日常生活',
      'エンタメ',
      'お笑い',
      '漫画',
      'アニメ',
      'ペット',
      '動物',
      '経営',
      '起業',
      '就活',
      '恋愛',
      '育児',
      '人間関係'
    ]
    break;

  case "us":
    initialTopics = [
      'IT',
      'Game',
      'Sport',
      'Music',
      'Science',
      'Finance',
      'News',
      'Politics'
    ]
    break;

  default:
    initialTopics = [
      'IT',
      'テクノロジー',
      '科学',
      '映画',
      '料理',
      'グルメ',
      '音楽',
      '本',
      '旅行',
      'ビジネス',
      '心理学',
      '歴史',
      '健康',
      '写真',
      'スポーツ',
      'ゲーム',
      'ファッション',
      '美容',
      '運動',
      '勉強',
      '学校',
      '経済',
      '政治',
      'ファイナンス',
      '金融',
      'テレビ',
      '芸能',
      '教育',
      '報道',
      '文学',
      'アート',
      '芸術',
      '数学',
      '哲学',
      '物理',
      '医療',
      '化学',
      '日常生活',
      'エンタメ',
      'お笑い',
      '漫画',
      'アニメ',
      'ペット',
      '動物',
      '経営',
      '起業',
      '就活',
      '恋愛',
      '育児',
      '人間関係'
    ]

    break;
}




export default initialTopics;