import React from 'react';
// import * as styles from '../css/Home.module.css';

const Intro = (props: any) => {
  return (
    <div style={{backgroundColor: "white", margin: 10}}>
      <h1>開発の様子</h1>
      <h2>今後の追加機能</h2>
      <h3>必要最低限付けたい機能</h3>
      <ul>
        <li>投票の見栄えを整える（とくにMJ法）</li>
        <li>MJ法の候補を入力可能にする（現在は、いい・ふつう・わるいとかの固定）</li>
        <li>投票結果の絞り込みを、複数同時に比べられるようにする。よりinteractiveに</li>
        <li>初回作成時に、ユーザ情報を入力させる</li>
      </ul>
      <h3>将来的には欲しい</h3>
      <ul>
        <li>トピック機能（投稿ごとに対象を選べる．トピックを紐づけられる．ユーザ側にも好きなトピックが紐付けられ，合致したトピックの投稿がおすすめされてくる．ネトフリの初回登録のようなもの）</li>
        <li>many more（手元のメモにある）</li>
      </ul>
      <h3>なければならない</h3>
      <ul>
        <li>利用規約・プラポリ</li>
        <li>アカウント削除や，パスワード変更など</li>
        <li>Rate Limiter</li>
        <li>バリデーションや文字数制限，エラーハンドルを丁寧に作る（すぐできる類のもの＆達成感が低いため，あとまわしにしがち）</li>
      </ul>

      <h2>現状のバグや修正項目など</h2>
      <ul>
        <li>投稿・コメント時に，連打すると重複で投稿できる</li>
        <li>人気順のキャッシュがたまに消えず、投票してもフィードが更新されない</li>
        <li>Enterキーで勝手に投稿できてしまう</li>
        <li>最新順の表示の順番が，日時順になってない場合があるような</li>
        <li>タブレット・PCにおいて，投稿時に枠外をクリックするとキャンセルしてしまう</li>
        <li>改行しても、投稿結果に反映されない（改行が無視される）</li>
        <li>職業選択の候補を有限に絞る</li>
        <li>年齢の入力を誕生日に</li>
        <li>投稿枠をenter押したら自動拡大など</li>
        <li></li>

        <li>その他、<a href="https://jp.hearvo.com/posts/168">ここの</a>コメント欄にて教えてください</li>
      </ul>


















      <hr></hr>
      <h1>Deprecated.</h1>
      <h1>はじめまして、Hearvoへようこそ。</h1>
      <p>
        このサービスは、Twitterの投票機能とRedditのスレッド機能を参考に作られました。人の意見が投票という形でもっと可視化される世界を目指しています。使い方は簡単です。人の意見を知りたい時に、そのアイデアを投票候補と共に投稿してみてください。
      </p>
      <h2>特徴</h2>
      <h3>複数の投票形式</h3>
      <p>
        Hearvoには二つの投票タイプがあります。通常投票と、MJ(Majority Judgement)法です。通常投票は、みなさんが親しみのある、複数の候補に対して一つしか投票できない形式のものです。一方，MJ法は，それぞれの投票候補に対して、「最高・いい・普通・悪い・最悪」のように段階的に選ぶことができます。どちらもメリット、デメリットがありますが、一般にMJ法の方が優れた投票形式とされています。このような投票方法を広めることも、Hearvoの意義の一つです。
      </p>
      <h3>投票結果の属性による分類・可視化</h3>
      <p>
        Twitterの投票機能では、投票結果を属性ごとに分析して、結果の中にどのようなクラスタが存在するのか、ということの解析は不可能でした。Hearvoでは、それを可能にします。ユーザーは登録の際に年齢・性別・職業などを登録することで、投票結果を各ユーザーの属性によって分類することができます。
      </p>
      <p>
        この機能の懸念点は、その属性が本当に正しいかどうかがわからないということです。Hearvoのアカウント作成の際には、個人の証明のようなものは求めていません。そのため、誰でも偽った年齢・性別・職業で登録できます。これでは、結果の解釈も意味を為さないでしょう。そのため、Hearvoでは将来的に、本人認証機能を実装しようと考えています。ちょうど、証券口座をネット上で作成する際に免許証やパスポートが求められるように、Hearvoでも相応の厳格な本人認証機能を追加で作成したいと考えています。もちろん、その手間は大きいですが、それによって本人確認の取れたユーザーだけの結果を見ることが可能になります。これは、他の本人認証をしていないユーザー層と結果にどれだけの解離があるかの証明にもなりますし、ユーザーの年齢や性別・職業といった属性の信頼性が格段に高まり、結果の解釈が用意になります。もちろん、本人認証の取れていないユーザーだけで投票結果を見るのも十分に面白いと思いますが（現在のTwitterのように）、本人認証がされた上での投票結果を見ることができるというのは、大きな意味があると考えています。
      </p>

      <p>
        ぜひ、Hearvoで遊んでみてください。
      </p>
    </div>
  )
}

export default Intro;